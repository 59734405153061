<template>
  <div>
    <div class="page-heading">
      <div class="page-title">
        <h3>{{ page?.title }}</h3>
      </div>
      <div class="row">
        <div class="col-12">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link
                    :to="{ name: 'PagesIndexView'}">{{ $t('pages.pages') }}
                </router-link>
              </li>
              <li class="breadcrumb-item" v-if="page?.ancestors.length">
                <router-link
                    v-for="ancestor in page?.ancestors" :key="ancestor.id"
                    :to="{ name: 'PagesDetailView', params: { slug: ancestor.slug } }">{{ ancestor.title }}
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">{{ page?.title }}</li>
            </ol>
          </nav>
        </div>
      </div>
    </div>

    <div class="page-content">
      <div class="mb-4 text-end" v-show="editAllowed">
        <button
            class="btn btn-primary"
            @click="openModal(page.id)"
        ><i class="bi bi-file-earmark-plus"></i
        ><span class="ps-2">{{ $t('page_admin.editPage') }}</span></button>
      </div>
      <div class="row">
        <div class="col-12 col-md-3">
          <div class="pages-list-wrap">
            <div class="pages-list-title">
              Podstránky
            </div>
            <ul class="pages-list" v-if="page?.children.length">
              <li v-for="child in page.children" :key="child.slug">
                <router-link :to="{ name: 'PagesDetailView', params: { slug: child?.slug } }" class="pages-list-item">
                  <span class="pages-list-item-title">{{ child.title }} <i class="bi bi-eye-slash"
                                                                           v-if="!child.is_visible"></i><i
                      class="bi bi-chevron-right"></i></span>
                </router-link>
              </li>
            </ul>
          </div>


          <!--          <div class="card mb-3">
                      <div class="card-header fw-bold">{{ $t('pages.childrenPages') }}</div>
                      <div class="card-body">
                        <div class="card-text" v-if="!page?.children.length">{{ $t('pages.noChildrenPages') }}</div>
                        <div class="card-text" v-else>
                          <router-link
                              class="nav-link"
                              v-for="child in page.children"
                              :key="child.id"
                              :to="{ name: 'PagesDetailView', params: { slug: child.slug } }"
                          >{{ child.title }}
                          </router-link>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>-->
        </div>
        <div class="col-12 col-md-9">
          <div class="card mb-3">
            <div class="card-body">
              <div class="card-text pb-3 mb-2 border-bottom fw-bold">{{ page?.description }}</div>
              <div class="card-text" v-html="page?.content"></div>
            </div>
            <div>
            </div>
          </div>
          <div v-if="page?.documents.length">
            <DocumentsCardComponent :page="page"></DocumentsCardComponent>
          </div>
        </div>
      </div>
    </div>
    <Modal ref="PageAdminModal" :pages="pages"/>
  </div>
</template>

<script>
import http from '@/http';
import TokenService from "@/services/token-service.js";
import Modal from "@/components/page_admin/PageAdminModal.vue";

export default {
  components: {
    Modal,
  },

  data() {
    return {
      page: null,
      pages: [],
      isLoading: false,
      editAllowed: false,
    };
  },

  mounted() {
    this.getPage();
    this.loadPages();
    this.emitter.on('pageSaved', (page) => {
      this.loadPages();
      this.page = page;
    });
    this.emitter.on('documentUploaded', () => {
      this.getPage();
    });
    this.canEdit();
  },

  watch: {
    '$route.params.slug': 'getPage'
  },

  methods: {
    getPage() {
      let self = this;
      http.get('/pages/' + self.$route.params.slug).then((response) => {
        self.page = response.data.page;
      });
    },

    loadPages() {
      let self = this;
      self.isLoading = true;
      http.get('/pages').then((response) => {
        self.pages = response.data.pages;
        self.isLoading = false;
      });
    },

    openModal(id) {
      let page = this.pages.find(page => page.id === id);
      this.$refs.PageAdminModal.toggleModal(page);
    },

    canEdit() {
      this.editAllowed = TokenService.getRoleLevel() === 5;
    }


    /*formatPercentage: function (value) {
      return parseFloat(value).toFixed(2) + ' %';
    },

    formatNumber: function (value) {
      return parseFloat(value).toFixed(2);
    },

    parseDate: function (value) {
      return new Date(value).toLocaleDateString();
    }*/
  }
};
</script>
